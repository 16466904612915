input[type=number] {
    -moz-appearance: textfield;
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none; /* Chrome, Safari, Edge */
    margin: 0; /* Reset margin */
}
.register-vehicle-container {
    width: 35dvw;
}
.register-vehicle-container-2 {
    width: 75dvw;
}
.register-vehicle-textbox {
    height: 35px;
    width: 30dvw !important;
    border: none;
    border-bottom: 2px solid #000;
}
.register-vehicle-textbox:-webkit-autofill {
    background: transparent !important;
}
.register-vehicle-textbox:focus {
    outline: transparent;
    background-color: transparent;
}
.register-vehicle-file {
    height: 35px;
    width: 30dvw !important;
    background-color: rgba(25, 29, 128, 1) !important;
    color: #fff !important;
}
.register-vehicle-button {
    height: 50px;
    background-color: rgba(25, 29, 128, 1);
    border: none;
    width: 12dvw !important;
}

.register-vehicle-button:hover {
    background-color: rgb(98, 101, 201);
}

/* CSS for the register request UI */
.image-preview {
    width: 28.5dvw;
    height: 35vh;
    border: 1px dashed #000;
    margin-top: 1%;
}
.step-p {
    font-size: 14px;
    margin: 0;
}
.image-textbox {
    padding-top: 10px;
    background-color: rgba(237, 242, 247, 1);
    height: 50px;
}
.image-label {
    margin-bottom: -3%;
}

@media (max-width: 768px) {
    .register-textbox, .image-textbox {
        width: 63vw !important;
        height: 3rem;
    }
    .register-vehicle-textbox {
        width: 100% !important;
    }
    .register-vehicle-button {
        width: 40% !important;
    }
    .register-vehicle-container {
        margin-top: 90% !important;
        width: 90dvw ;
        padding: 20% !important;
    }
    .register-p {
        font-size: 1rem;
    }
    .image-label {
        font-size: 0.7rem;
    }
    .register-button {
        height: 3rem;
        width: 40% !important;
    }
}
