.cctv-label {
    width: 85vw;
}
.video-feed-container {
    height: 360px;
    width: 640px;
    border: 1px solid #000;
}
.video-feed-container p {
    position: absolute;
    background: rgba(25, 29, 128, 1);
    color: #fff;
    font-weight: bold;
    width: inherit;
}
img {
    height: 360px;
    width: 640px;
    object-fit: cover;
}
.video-container {
    display: grid;
    grid-template-columns: repeat(2, auto); /* 2 columns */
    grid-template-rows: repeat(2, auto); /* 2 rows, auto adjusts to content height */
}
.no-camera-message {
    width: 85vw;
}
.feed-container {
    margin-left: 15.5% !important;
}
.sidenav-position {
    position: fixed;
}

@media (max-width: 768px) {
    .home-page {
        flex-direction: column !important;
    }
}
