.modal-header {
    background: rgba(25, 29, 128, 1);
}
.edit-modal-label {
    width: 35% !important;
}
.edit-modal-textbox {
    background-color: rgb(219, 230, 241);
    width: 65% !important;
}
.pending-modal-content {
    margin-left: 3rem;
}
.detail {
    flex-direction: row;
}

@media (max-width: 768px) {
    .detail label {
        font-size: 0.7rem;
        height: 0.7rem;
        width: 100% !important;
    }
    .pending-modal p, a {
        font-size: 0.7rem !important;
    }
    .pending-modal {
        overflow-x: scroll;
    }
    .pending-modal-content {
        margin-left: 0% !important;
    }
    .image-preview {
        width: 150% !important;
        margin-right: 7%;
    }
    .detail {
        flex-direction: column;
        padding: 0% !important;
    }
}