.users-table, .vehicles-table, .cameras-table, .logs-table, .pending-table {
    width: 85vw;
}
.rdt_TableBody .rdt_TableRow:nth-child(even) {
    background-color: #f1ecec;  /* Light grey for even rows */
}
.rdt_TableBody .rdt_TableRow:nth-child(odd) {
    background-color: #ffffff;  /* White for odd rows */
}
.nav-tabs .nav-link {
    color: #000;
}
.nav-tabs .nav-link:hover {
    font-weight: bold;
}
.nav-tabs .nav-link.active {
    font-weight: bold;
}

@media (max-width: 768px) {
    .users-table, 
    .vehicles-table, 
    .cameras-table, 
    .logs-table, 
    .pending-table,
    .register-list {
      width: auto;
      height: 90dvh !important;
    }
    .nav-tabs .nav-link {
        width: 100vw;
    }
    .logs-table h5 {
        font-size: 0.8rem;
        margin-left: -4%;
        margin-top: 3%;
    }
    .logs-table button {
        height: 1.7rem;
        font-size: 0.8rem;
    }
    .logs-table input {
        height: 1.5rem;
        font-size: 0.8rem;
        width: 6rem;
    }
}