.sidebar .nav-link {
    color: #fff;
    margin-bottom: 1rem;
    width: 14vw;
    margin-left: 1vw;
}

.sidebar{
    height: 100dvh;
}

.sidebar .nav-link:hover {
    background-color: #323689;
    font-weight: bold;
    color: #fff;
    transition: all 0.5s ease;
}
.white-hr {
    border: none;
    border-top: 3px solid white;
}
.nav-button {
    width: 15vw;
}
.active-link {
    background: #fff !important;
    color: #000 !important;
    font-weight: bold !important;
}
.hamburger-button {
    display: none;
}
.sidenav-background {
   background: linear-gradient(to bottom, #191D80, #0a0a0a) !important;
}

@media (max-width: 768px) {
    .hamburger-button {
        background: none !important;
        border: none !important;
        display: block;
        width: 10dvw;
        color: #000;
    }
    .sidebar.closed {
        display: none !important;
    }
    .sidebar.open {
        position: absolute;
        width: 50dvw;
        height: 90dvh;
        background: linear-gradient(to bottom, #191D80, #0a0a0a);
        z-index: 2;
    }
    .sidebar .nav-link {
        width: 50dvw;
    }
}
