.loginBackground {
    background: linear-gradient(to bottom, #191D80, #0a0a0a);
    width: 100dvw;
    height: 100dvh;
    overflow-x: hidden;
    overflow-y: auto;
}
.login-textbox {
    height: 35px;
    border: none;
    border-bottom: 2px solid #000;
}
.login-textbox:focus {
    outline: transparent;
    background-color: transparent;
}
.login-textbox:-webkit-autofill {
    background: transparent !important;
  }
.login-button {
    height: 50px;
    width: 20vw !important;
    background-color: rgba(25, 29, 128, 1);
}
.login-button:hover, 
.login-button:active {
    background-color: rgb(98, 101, 201);
}

.login-container {
    width: 35dvw;
}

@media (max-width: 480px) {
    .login-container {
        width: 35dvw !important;
    }
    .login-button {
        width: 1vw !important;
    }
    .login-p, .login-a {
        font-size: 1rem;
    }
}

@media (max-width: 900px) {
    .login-button {
        width: 35dvw !important;
    }
    .login-container {
        width: 80vw !important;
    }
}