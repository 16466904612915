.register-container {
    height: auto;
    width: 85dvw;
}
.register-page {
    width: 100dvw;
    overflow-x: hidden;
}
.register-content {
    flex-direction: row;
    margin-left: 1%;
}
.select-box {
    background-color: rgba(237, 242, 247, 1);
    height: auto;
    width: 15%;
}
.register-button {
    height: 50px;
    background-color: #fff;
}
.register-button:hover, 
.register-button:active {
    background-color: rgb(185, 186, 208);
}

@media (max-width: 768px) {
    .register-page {
        flex-direction: column !important;
    }
    .register-container {
        width: 100dvh;
    }
    .register-content h3 {
        font-size: 1rem;
        padding-top: 1%;
    }
    .register-content select {
        width: 30%;
        height: 1.8rem;
    }
    .register-form-container {
        width: 100dvw;
    }
}