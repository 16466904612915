.user-form, 
.vehicle-form, 
.camera-form {
    width: 85vw;
}
.register-textbox {
    background-color: rgba(237, 242, 247, 1);
    height: 50px;
    width: 85% !important;
}
.user-form-container,
.vehicle-form-container,
.camera-form-container {
    background: linear-gradient(to bottom, #191D80, #0a0a0a);
    width: 35dvw;
}